<template>
    <div>
        <van-popup
            v-model="showPopup"
            position="bottom"
            class="poem-popup"
            safe-area-inset-bottom
            close-on-popstate
            :lock-scroll="false"
            @closed="$refs.aplayer.audio.pause()"
        >
            <van-sticky class="poem-title" :offset-top="20">
                <p>{{ poem.title }} (第 {{poem.track_sn}} 首)</p>
                <a href="" @click.prevent="showPopup = false">返回目录</a>
            </van-sticky>
            <van-panel v-if="haveMusic">
                <my-image :src="poem.thumb" @click.native="handlePreview" />
                <aplayer ref="aplayer" :music="music" preload="none" class="poem-player"/>
            </van-panel>
        </van-popup>
        <van-image-preview closeable v-model="showPreview" :images="[poem.thumb]" />
    </div>
</template>

<script>
import Aplayer from 'vue-aplayer'
import MyImage from '@/components/MyImage'

export default {
    name: "PoemReader",
    props: {
        poem: {
            type: Object,
            required: true
        },
        mountOn: String
    },
    components: { Aplayer, MyImage },
    methods: {
        show () {
            this.showPopup = true
        },
        handlePreview () {
            this.showPreview = true
        }
    },
    computed : {
        haveMusic () {
            return this.poem && this.poem.url
        },
        music () {
            return {
                src: this.poem.url,
                title: this.poem.title,
                pic: 'xx',
                artist: ' '
            }
        }
    },
    data() {
        return {
            showPopup1: false,
            showPopup: false,
            showPreview: false,
            opened: false
        }
    }
}
</script>

<style lang="less">
    .poem-popup {
        width: 100%;
        height: 90%;
        padding: 10px 16px;
        box-sizing: border-box;

        .poem-title {
            p {
                margin: 0px;
                padding: 10px 0px;
                background: #fff;
                font-size: 18px;
            }
            .van-sticky {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #fff;

                a {
                    color: #1989fa;
                    font-size: 14px;
                }
            }
            .van-sticky--fixed {
                margin: 0px 24px;

                a {
                    display: inline-block;
                }
            }
        }
        .van-panel__header {
            display: none;
        }
    }
</style>
<style lang="less">
    .aplayer {
        margin: 10px 0px !important;
        border-radius: 0px !important;
        .aplayer-body {
            .aplayer-info {
                padding: 10px 7px !important;
                .aplayer-music {
                    margin-left: 0px !important;
                }
            }
        }
    }
</style>
