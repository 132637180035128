<template>
    <div>
        <div id="mse"></div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入曲目号或者标题搜索"/>
        <van-list
            :offset="50"
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="加载,点击重新加载"
        >
            <van-row style="padding: 5px 0px">
                <van-col span="24" v-for="poem in poems.data" :key="poem.id" >
                    <div class="poem-list-item van-ellipsis">
                        <span @click="handlePoemPlay(poem)">{{ poem.track_sn + '. ' + poem.title }}</span>
                        <div class="poem-actions">
                            <van-icon name="video-o" @click="handlePoemPlay(poem)" />
                            <van-icon name="music-o" @click="handlePoemDetail(poem)" />
                        </div>
                    </div>
                </van-col>
            </van-row>
        </van-list>
        <poem-reader :poem="showPoem" ref="poem" />
    </div>
</template>

<script>
import { getPoems } from '@/api/bible'
import wechatMixin from '@/utils/mixin'
import PoemReader from "@/components/PoemReader";
import XGPlayer from "xgplayer";

const scrollToTop = () => {
    let sTop = document.documentElement.scrollTop || document.body.scrollTop
    if (sTop > 0) {
        window.requestAnimationFrame(scrollToTop)
        window.scrollTo(0, sTop - sTop / 8)
    }
}

export default {
    name: 'PoemIndex',
    mixins: [wechatMixin],
    components: { PoemReader },
    data () {
        return {
            player: null,
            keyword: null,
            categoryLoading: true,
            listLoading: false,
            listFinished: false,
            error: false,
            poems: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            },
            showPoem: {
                url: '',
                title: '',
                track_sn: '',
                video_uul: ''
            },
            poem: {
                url: '',
                title: '',
                track_sn: '',
                video_uul: ''
            },
            playerOptions: {
                id: 'mse',
                url: '',
                fluid: true,
                videoInit: true,
                poster: '',
                ignores: ['volume'],
                playsinline: true,
                playbackRate: [0.5, 0.75, 1, 1.5, 2],
                noLog: true,
                preload: 'meta',
                preloadTime: 5,
                closeVideoClick: false,
                closeVideoTouch: false,
                definitionActive: 'click',
                autoplay: true,
                playNext: {
                    urlList: []
                }
            }
        }
    },
    mounted() {
        this.registerWechatShare({
            title: '赞美诗集',
            description: '【启19:5】有声音从宝座出来说：“神的众仆人哪，凡敬畏他的，无论大小，都要赞美我们的神。”',
            thumb: 'https://file4.mogody.com/avatars/bible.jpg'
        })
    },
    watch: {
        poem(newVal) {
            if (this.player !== null) {
                this.player.src = newVal.video_url
            } else {
                this.playerOptions.url = newVal.video_url
                this.player = new XGPlayer(this.playerOptions)
            }
        }
    },
    methods: {
        handlePoemPlay (poem) {
            if (this.poem.id === poem.id) {
                return
            }
            this.poem = poem

            scrollToTop()
        },
        handlePoemDetail (poem) {
            this.showPoem = poem
            this.$refs.poem.show()
        },
        handleSearch () {
            this.refresh()
        },
        handleClear () {
            this.keyword = null
            this.refresh()
        },
        refresh () {
            this.poems.data = []
            this.poems.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        loadSuccess() {
            //处理下一集播放
            this.poems.data.forEach(item => {
                this.playerOptions.playNext.urlList.push(item.video_url)
            })
            //处理第一首
            if (this.player === null) {
                this.poem = this.poems.data[0]
            }
            this.$nextTick(() => {
                //播放结束后自动进入下一首
                this.player.on('ended', () => {
                    const end = this.poems.data[this.poems.data.length - 1]

                    if (end.id === this.poem.id) {
                        this.poem = this.poems.data[0]
                        return
                    }
                    for (let i = 0; i < this.poems.data.length; i++) {
                        const item = this.poems.data[i]
                        if (this.poem.id === item.id) {
                            this.poem = this.poems.data[i + 1]
                            break
                        }
                    }
                })
            })
        },
        onLoad () {
            getPoems({
                pageSize: 500,
                page: this.poems.meta.current_page + 1,
                keyword: this.keyword
            }).then(res => {
                res.data.unshift(...this.poems.data)
                this.poems = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
                this.loadSuccess()
            }).catch(() => {
                this.error = true
            })
        }
    }
};
</script>

<style lang="less">
#mse{

}
.poem-list-item {
    background: #fff;
    border-radius: 4px;
    font-size: 16px;
    line-height: 28px;
    width: auto;
    padding: 8px 12px;
    margin: 8px 12px;
    display: flex;
    justify-content: space-between;
}
.poem-actions {
    i {
        margin: 0px 6px;
    }
}
</style>

