<template>
    <van-image
        :src="src"
        @load="handleImgLoad"
        :class="load ? null : 'full-width'">
    </van-image>
</template>

<script>
export default {
    name: "MyImage",
    props: {
        src: String
    },
    methods: {
        handleImgLoad () {
            this.load = true
        }
    },
    data() {
        return {
            load: false
        }
    }
}
</script>

<style scoped>
    .full-width {
        width: 100%;
        height: 100%;
    }
</style>
